@import "styles/reset.module";
@import "styles/vars.module";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");

@font-face {
  font-family: "Arsinova Deco";
  src: url("./fonts/ArsinovaDeco.ttf");
}

html {
  font-size: 18px;
  @media (max-width: 700px) {
    font-size: 16px;
  }
}

body {
  font-family: "Inter", sans-serif;
  background-color: $main-beige;
  font-weight: 300;
  line-height: 130%;
}

.wrapper {
  height: 100%;
}

.container {
  max-width: 1145px + 20px;
  margin: 0 auto;
  padding: 0 20px;
  @media (max-width: 1400px) {
    padding: 0 40px;
  }
}
.content {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 900px) {
    flex-direction: column;
  }
}
.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 35vw;

  &__title {
    font-size: 2.8rem;
    font-family: "Arsinova Deco", sans-serif;
    letter-spacing: 5px;
    line-height: 100%;
    text-align: center;
    text-transform: lowercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  & > .page__title {
    min-height: 150vh;
  }
  @media (max-width: 1400px) {
    max-width: 45vw;
  }
  @media (max-width: 900px) {
    max-width: 70vw;
    margin: 0 auto;
  }
  @media (max-width: 450px) {
    margin: 0;
    max-width: 100vw;
  }
}
