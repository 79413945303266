@import "styles/vars.module.scss";

.projectCard {
  background-color: $accent-grey;
  border-radius: $border-radius;
  padding: 20px;
  display: flex;
  gap: 10%;
  &__img {
    flex: 0 0 60%;
    margin-left: -15%;
  }
  &__description {
    display: flex;
    align-items: center;
    font-size: 1.8rem;

    & a {
      color: $text-grey;
      font-family: "Arsinova Deco", sans-serif;
      letter-spacing: 5px;
      line-height: 100%;
    }
  }
  @media (max-width: 570px) {
    &__description {
      font-size: 1.5rem;
    }
  }
  @media (max-width: 500px) {
    &__description {
      font-size: 1.2rem;
    }
  }
  @media (max-width: 450px) {
    flex-direction: column;
    &__img {
      margin-left: -25%;
    }
    &__description {
      margin-top: 20px;
      font-size: 1rem;
    }
  }
}
