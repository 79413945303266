@import "styles/vars.module.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 120px;
}
.title {
  font-size: 1.11rem;
  margin-top: 25px;
  color: black;
  display: inline-block;
  position: relative;
  /* text-decoration: underline 1px rgba(0, 0, 0, 0);
  text-underline-position: under;
  transition: text-decoration 0.3s;
  &:hover {
    text-decoration-color: black;
  } */
  @media (min-width: 900px) {
    &::after {
      content: "";
      transition: all 0.5s;
    }
    &:hover::after {
      content: "";
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: black;
    }
  }
}
.description {
  margin-top: 15px;
  font-size: 1rem;
  color: $text-grey;
  @media (max-width: 450px) {
    font-size: 0.88rem;
  }
}

.techStack {
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
  &__item {
    border: 1px solid $text-grey;
    border-radius: 10px;
    padding: 5px 10px;
    color: $text-grey;
    font-size: 0.88rem;
    cursor: default;
  }
}

.project {
  &__bottom {
    margin-top: 25px;
  }
}
