@import "styles/vars.module.scss";

.container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  font-size: 1rem;
  height: 80vh;
  max-width: 300px;
}

.social {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  margin-top: 40px;
  margin-bottom: 40px;
  color: $text-grey;
  & a {
    color: black;
  }
}

.email {
  margin-top: 60px;
  color: black;
}

.description {
  padding: 30px;
  border-radius: $border-radius;
  background-color: $accent-red;
  max-width: 300px;
  line-height: 130%;
}
