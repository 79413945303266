@import "styles/vars.module.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  & > :nth-child(2n-1) {
    background-color: $accent-grey;
  }
}

.category {
  width: 100%;
  border-radius: $border-radius;
  padding: 30px 40px;
}

.title {
  text-transform: uppercase;
  font-size: 1.11rem;
}

.list {
  color: $text-grey;
  font-size: 1rem;
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  gap: 15px;
  & li {
    padding-left: 15px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 10px;
      left: 0;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $text-grey;
    }
  }
}
