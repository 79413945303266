@import "styles/vars.module.scss";

.container {
}
.sidebar {
  position: fixed;
  top: 50%;
  left: 0;
  padding: 0 10%;
  transform: translate(0, -50%);
  @media (max-width: 1400px) {
    max-width: 45vw;
    padding: 0 40px;
  }
  @media (max-width: 900px) {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-width: 100%;
    min-height: 100vh;
    padding: 0;
    & nav {
      align-self: center;
    }
  }
}
.info {
  margin-bottom: 60px;
}

.logo {
  background-color: $accent-red;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  border-top-left-radius: 0;
  position: relative;
  margin-bottom: 30px;
  &::after {
    content: "захар кудрявцев";
    font-size: 2.8rem;
    font-family: "Arsinova Deco", sans-serif;
    letter-spacing: 5px;
    line-height: 100%;
    position: absolute;
    top: 22%;
    left: 20%;
  }
  &:hover {
    &::after {
      content: "захар кудрявце";
      font-size: 2.8rem;
      font-family: "Arsinova Deco", sans-serif;
      letter-spacing: 5px;
      line-height: 100%;
      position: absolute;
      top: 22%;
      left: 20%;
    }
    &::before {
      content: "в";
      font-size: 50px;
      font-family: "Arsinova Deco";
      letter-spacing: 5px;
      line-height: 100%;
      position: absolute;
      top: 60%;
      right: -32%;
      transition: all;
    }
  }
}

.title {
  font-size: 1rem;
  margin-bottom: 15px;
  @media (max-width: 450px) {
    font-size: 0.88rem;
  }
}
.description {
  font-size: 1rem;
  max-width: 400px;
  color: $text-grey;
  @media (max-width: 450px) {
    font-size: 0.88rem;
  }
}

.menu {
  li {
    margin-bottom: 10px;
    font-size: 0.88rem;
  }
  a {
    color: $text-grey;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    position: relative;
    padding-left: 70px;
    transition: all 0.3s;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 60px;
      height: 1px;
      background-color: $text-grey;
      transition: all 0.3s;
    }
  }
  a:hover {
    color: black;
    cursor: pointer;
    padding-left: 80px;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 70px;
      height: 1px;
      background-color: black;
    }
  }
}
