$main-beige: #f7ede8;
$accent-red: #e3a2a2;
$accent-grey: #e5e4e4;
$text-grey: #949191;

$border-radius: 25px;

@mixin arrowheadCreate($color, $deg, $offset, $headPosition, $translateX) {
  position: absolute;
  top: $offset;
  left: $headPosition;
  height: 1px;
  width: 8px;
  background-color: $color;
  transform: translateX($translateX) rotate($deg);
}

.btnRight {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: $text-grey;
  padding-right: 20px;
  line-height: 150%;
  cursor: pointer;
  & span {
    flex: 0 0 37%;
    height: 1px;
    background-color: $text-grey;
    position: relative;
    transition: flex 0.3s;
    &::after {
      content: "";
      @include arrowheadCreate($text-grey, -45deg, 3px, 100%, -7px);
    }
    &::before {
      content: "";
      @include arrowheadCreate($text-grey, 45deg, -3px, 100%, -7px);
    }
  }
  &:hover {
    color: black;
    span {
      flex: 0 0 47%;
      background-color: black;
    }
    span::after {
      content: "";
      @include arrowheadCreate(black, -45deg, 3px, 100%, -7px);
    }
    span::before {
      content: "";
      @include arrowheadCreate(black, 45deg, -3px, 100%, -7px);
    }
  }
}

.btnLeft {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: $text-grey;
  padding-right: 20px;
  line-height: 150%;
  cursor: pointer;
  & span {
    flex: 0 0 37%;
    height: 1px;
    background-color: $text-grey;
    position: relative;
    transition: flex 0.3s;
    &::after {
      content: "";
      @include arrowheadCreate($text-grey, 45deg, 3px, 0, 0);
    }
    &::before {
      content: "";
      @include arrowheadCreate($text-grey, -45deg, -3px, 0, 0);
    }
  }
  &:hover {
    color: black;
    span {
      flex: 0 0 47%;
      background-color: black;
    }
    span::after {
      content: "";
      @include arrowheadCreate(black, 45deg, 3px, 0, 0);
    }
    span::before {
      content: "";
      @include arrowheadCreate(black, -45deg, -3px, 0, 0);
    }
  }
}
