@import "styles/vars.module.scss";

.container {
  display: flex;
  flex-direction: column;
}
.avatar {
  display: flex;
  width: 200px;
  height: 200px;
  background-color: $accent-red;
  border-radius: 50%;
  align-self: center;
  padding: 10px;

  &__img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
    background: url("../../../assets/img/avatar.jpg") 0 0 / cover no-repeat;
  }
}
.text {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  font-size: 1rem;
  line-height: 140%;
  @media (max-width: 450px) {
    font-size: 0.88rem;
  }
}
